import React from 'react';
import { graphql } from 'gatsby';
import { md2react } from 'helpers/parser';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import ResourceGrid from 'components/molecules/ResourceGrid';
import BlogPostHero from 'components/organisms/BlogPostHero';
import CtaSection from 'components/organisms/CtaSection';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';

const PressReleaseContent = ({ title, date, featuredImage, body }) => {
  return (
    <>
      <BlogPostHero
        title={title}
        imageUrl={featuredImage}
        date={date}
        background="bg-aqua"
        foreground="text-aqua"
      />
      <div className="u-section u-container px-6 lg:px-md">
        <div className="prose-xl lg:w-8/12">{md2react(body)}</div>
      </div>
    </>
  );
};

export const PressReleasePreview = ({ entry }) => {
  return <PressReleaseContent {...entry.get('data').toJS()} />;
};

const PressRelease = ({
  data: {
    markdownRemark: { frontmatter, rawMarkdownBody },
    related,
  },
}) => {
  const { seo, title, description } = frontmatter;

  const resources = related.edges.map(({ node }) => {
    const { title, slug, featuredImage, description } = node.frontmatter || {};
    return {
      title: title,
      url: `/press/${slug}`,
      thumbnailUrl: featuredImage,
      type: 'press-release',
      excerpt: description,
    };
  });

  return (
    <Layout>
      <SEO
        title={seo?.title || title}
        description={seo?.description || description}
        openGraphImage={seo?.openGraphImage}
      />

      <PressReleaseContent {...frontmatter} body={rawMarkdownBody} />

      <NewsletterCtaSection
        headline="Our blog is on the scanning edge. Send the latest stories to your inbox."
        background="bg-aqua text-white"
      />

      {resources.length > 0 && (
        <ResourceGrid
          lead="Related Articles"
          title="There’s always a lot going on at Skupos."
          cols={4}
          resources={resources}
        />
      )}

      <CtaSection headline="Unlock the power of 14,000+ retailers connected to Skupos" />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PressReleaseByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date
        featuredImage
        description
        seo {
          title
          description
          openGraphImage
        }
      }
      rawMarkdownBody
    }
    related: allMarkdownRemark(
      filter: {
        id: { ne: $id }
        frontmatter: {
          templateKey: { eq: "press-release" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featuredImage
            description
          }
        }
      }
    }
  }
`;

export default PressRelease;
